'use strict';

$(document).ready(function(){

    window.scrollToContactForm = function () {
        $('html, body').animate({
            scrollTop: $("#contact").offset().top
        }, 800);
    }
    
});

